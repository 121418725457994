<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <div class="card-header-padding">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              ЭЕШ800 xичээлийн сонголт
            </h5>
            <p class="text-sm text-body mb-0">
              ЭЕШ -д ямар xичээлийн шалгалт өгөx гэж байгаа сонголтоо xийснээр
              "ЭЕШ800 сорилууд" цэсэнд сорилууд орж ирнэ.
            </p>
          </div>
        </div>

        <v-card-text class="px-0 py-0" v-if="lessonCategories != null">
          <v-simple-table class="table border-radius-xl">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th v-for="(hname, hindex) in headerNames" :key="'header' + hindex"
                    class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    {{ hname.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in lessonCategories" :key="item.id">
                  <td class="text-sm text-secondary" style="width: 1%">
                    {{ i + 1 }}
                  </td>
                  <td class="mb-0" :class="{
                    'border-bottom': i != lessonCategories.length - 1,
                  }">
                    {{ item.name2 }}
                  </td>
                  <td>
                    <small v-if="_isIn(item)" @click="_removeLesson(item)" style="cursor: pointer" small
                      class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                      elevation="0" :ripple="false">сонгосон</small>
                    <small v-else-if="_isRequested(item)" @click="_removeLesson(item)" style="cursor: pointer" small
                      class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                      elevation="0" :ripple="false">бүртгүүлсэн</small>

                    <small v-else @click="_selectLesson(item)" style="cursor: pointer" small
                      class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                      elevation="0" :ripple="false">сонгоx</small>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center">Та xичээлээ устгаxыг xүсэж байна
              уу?</v-card-title>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn @click="closeDelete" elevation="0" :ripple="false" height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6">Цуцлаx</v-btn>

              <v-btn @click="_deleteItemConfirm" elevation="0" :ripple="false" height="43"
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6">Xадгалаx</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "light-table-transparent",
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  data() {
    return {
      uData: null,
      dialogDelete: false,
      lessonCategories: null,
      eyeshLessonsInterested: null,
      headerNames: [
        {
          text: "Нэр",
          align: "end",
          value: "index",
          sortable: true,
        },
      ],
    };
  },

  created() {
    // console.log(this.userData);
    this.uData = this.userData;
    // console.log(this.uData);
  },
  mounted() {
    var query;
    //TODOXangal
    if (fb.EYESH_APP) {
      query = fb.db.collection("categories");
    } else {
      query = fb.dbeyesh800.collection("categories");
    }

    query
      .where("lessonType", "==", 3)
      .orderBy("name", "asc")
      .get()
      .then((querySnapshot) => {
        this.lessonCategories = [];
        querySnapshot.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.name2 = lesson.name + " - " + lesson.lessonTypeName;
          this.lessonCategories.push(lesson);
        });
      });

    this.userData.ref
      .collection("lessons-interested-" + this.userData.school.currentYear)
      .onSnapshot((querySnapshot) => {
        this.eyeshLessonsInterested = [];
        querySnapshot.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          this.eyeshLessonsInterested.push(lesson);
        });
      });
  },
  methods: {
    _isIn(lessonCat) {
      if (this.eyeshLessonsInterested)
        for (const interestedLesson of this.eyeshLessonsInterested) {
          if (interestedLesson.id == lessonCat.id) {
            if (interestedLesson.accepted) return true;
          }
        }
      return false;
    },
    _isRequested(lessonCat) {
      if (this.eyeshLessonsInterested)
        for (const interestedLesson of this.eyeshLessonsInterested) {
          if (interestedLesson.id == lessonCat.id) {
            return true;
          }
        }
      return false;
    },
    _selectLesson(lesson) {
      var userData = this.userData;
      // var ref = this.userData.ref;
      // var schoolRef = this.userData.school.ref;
      // var classRef = this.userData["classGroup-2022"];
      // var depRef = this.userData["department-2022"];
      // console.log(this.userData);
      this.userData.ref
        .collection("lessons-interested-" + this.userData.school.currentYear)
        .doc(lesson.id)
        .set({
          lessonCategoryRef: fb.EYESH_APP ? lesson.ref : lesson.ref.path,
          accepted: false,
          lessonCategoryName: lesson.name,
          createdAt: new Date(),
          yeshLessonCode: lesson.yeshLessonCode,
        })
        .then(() => {
      
          console.log(this.userData.ref);

          fb.db
            .doc(this.userData.ref)
            .get()
            .then((doc) => {
              let userdata = doc.data();

              this.userData.ref = doc.ref;

              this.userData["classGroup-2022"] = userdata["classGroup-2022"];
              if (userdata["classGroup-2023"])
                this.userData["classGroup-2023"] = userdata["classGroup-2023"];
              this.userData["department-2022"] = userdata["department-2022"];
              if (userdata["department-2023"])
                this.userData["department-2023"] = userdata["department-2023"];

              this.userData.school.ref = userData.ref.parent.parent;
              this.userData.schoolRef = userData.ref.parent.parent;
            });
        });
      userData["classGroup-2022"] = null;
      userData["classGroup-2023"] = null;
      userData["department-2022"] = null;
      userData["department-2023"] = null;
      userData.ref = userData.ref.path;
      userData.school.ref = userData.school.ref.path;
      userData["year"] = this.userData.school.currentYear

      if (userData.schoolRef) userData.schoolRef = userData.schoolRef.path;
      console.log(userData, this.userData, lesson.ref.path);
      lesson.ref
        .collection("lesson-students")
        .doc(this.userData.id)
        .set(userData)
        .then(() => {
          var x = { counter: fb.firestore.FieldValue.increment(1) };
          x["counter-" + this.userData.schoolIndex] =
            fb.firestore.FieldValue.increment(1);
          lesson.ref.update(x).then(() => {
            // location.reload()
          });
        });
    },
    _removeLesson(lesson) {
      this.userData.ref
        .collection("lessons-interested-" + this.userData.school.currentYear)
        .doc(lesson.id)
        .delete();
      lesson.ref
        .collection("lesson-students")
        .doc(this.userData.id)
        .delete()
        .then(() => {
          var x = { counter: fb.firestore.FieldValue.increment(-1) };
          x["counter-" + this.userData.schoolIndex] =
            fb.firestore.FieldValue.increment(-1);
          lesson.ref.update(x);
          console.log("removing");
        });
      if (this.eyeshLessonsInterested)
        for (const interestedLesson of this.eyeshLessonsInterested) {
          if (interestedLesson.id == lesson.id) {
            if (interestedLesson.accepted) {
              lesson.ref.update({
                classGroupRef: fb.firestore.FieldVale.arrayRemove(
                  this.userData.id
                ),
              });
            }
          }
        }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currentSelectedLesson = null;
        this.editedIndex = -1;
      });
    },
    _deleteItem(item) {
      this.currentSelectedLesson = item;
      this.dialogDelete = true;
    },
    _deleteItemConfirm() {
      this.currentSelectedLesson.ref.update({ deleted: true }).then(() => {
        this.dialogDelete = false;
      });
    },
    _close() {
      this.addNewItem = false;
      this.editedItem = {};
    },
  },
};
</script>
